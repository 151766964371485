import React from 'react';
import { History } from 'history';
import { Grid, Typography } from '@mui/material';
import { Card, Chip, Icon, ParallaxScroll, ProgressBar, ScreenGuard, TextToHtml, Webcam } from '../../components';
import Model from './event-model';  //editable..
import UserModel from '../user/user-model';
import EventtypeModel from '../eventtype/eventtype-model';
import eventsectionModel from '../eventsection/eventsection-model';  
import ReportgroupModel from '../reportgroup/reportgroup-model';
import MedialibraryModel from '../medialibrary/medialibrary-model';
import BaseView from '../base/base-view.component';
import BaseEdit from '../base/base-edit.component';
import QuestionListByuser from '../question/question-list-byuser.component';
import EventsectionListByuser from '../eventsection/eventsection-list-byuser.component';
import EventCreateByuser from './event-create-byuser.component';
import EventShare from './event-share.component';
import AppService from '../../services/app.service';

interface IProps { 
    history: History;
}

interface IState {
    dataRow: Model;
    dataError: any;
    dataRowUser: UserModel;
    dataRowEventtype: EventtypeModel;
    dataRowReportgroup: ReportgroupModel;
    dataRowParent: Model;
    dataRowEvent: Model;
    dataRowEdit: any;
    dataRowEventsection: eventsectionModel;
    dataRowMedia: any;
    totalQuestion: number;
    valueQuestion: number;
    timeLeft: number;
    stopCapture: boolean;
    saveCapture: boolean;
    isOnline: boolean;
    allowCamera: boolean;
    offlineDuration: number;
    finish: boolean;
    sendReport: boolean;
    isQuiz: boolean;
}

export default class EventViewByuser extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            dataRow: Model.defaultValue(),
            dataError: null,
            dataRowUser: UserModel.defaultValue(),
            dataRowEventtype: EventtypeModel.defaultValue(),
            dataRowReportgroup: ReportgroupModel.defaultValue(),
            dataRowParent: Model.defaultValue(),
            dataRowEvent: Model.defaultValue(),
            dataRowEdit: null,
            dataRowEventsection: eventsectionModel.defaultValue(),
            dataRowMedia: null,
            totalQuestion: 0,
            valueQuestion: 0,
            timeLeft: -1,
            stopCapture: false,
            saveCapture: false,
            isOnline: true,
            allowCamera: false,
            offlineDuration: 0,
            finish: false,
            sendReport: false,
            isQuiz: false,
        }
    }

    private historyState: any = this.props.history.location.state;

    private getDataRow = (data:any) => {
        if (data) {
            if (!this.historyState?.eventInfo && !data.parent) {
                var eventInfo = {name: data.name, startDate: AppService.dateFormat(data.startdate ||'', 'd mon y t'), endDate: AppService.dateFormat(data.enddate ||'', data.startdate?.substring(0,10)===data.enddate?.substring(0,10)? 't':'d mon y t')}
                this.props.history.replace(this.props.history.location.pathname, {...this.historyState, eventInfo: eventInfo});
            }
            else if (!this.historyState?.eventInfo?.status && data.parent) {
                this.props.history.replace(this.props.history.location.pathname, {...this.historyState, eventInfo: {...this.historyState.eventInfo, status: data.status}});
            }
            this.setState({dataRow: data, dataRowEdit: {enddate: data.enddate}, valueQuestion: data.eventquestionLengthAnswered});
        }
    }

    private getDataRowUser = (data:any) => {
        this.setState({dataRowUser:  data});
    }

    private getDataRowEventtype = (data:any) => {
        this.setState({dataRowEventtype:  data, isQuiz: data?.type.includes('quiz')});
    }

    private getDataRowReportgroup = (data:any) => {
        this.setState({dataRowReportgroup:  data});
    }

    private getDataRowParent = (data:any) => {
        this.setState({dataRowParent: data});
    }

    private getDataRowEvent = (data:any) => {
        this.setState({dataRowEvent: data});
    }

    private getDataRowCamera = (data:any) => {
        this.setState({dataRow: data});
    }

    private getDataRowEdit = async(data?:any) => {
        const endDate = localStorage.getItem('eventEnddate'+this.state.dataRow.id);
        await this.setState({dataRowEdit: { enddate : endDate || AppService.dateCurrent()}});
    }

    private getDataRowEventsection = (data: any) => {
        this.setState({dataRowEventsection:  data});
    };

    private getDataRowQuestion = (data: any) => {
        this.setState({totalQuestion:  data.total});
    }

    private getDataRowEventsectionList = (data: any) => {
        this.setState({finish: data.finish});
        if (!this.historyState?.parentId && data.finish)
            this.props.history.replace(this.props.history.location.pathname, {...this.historyState, parentId: this.state.dataRow.id});
            
    };

    private getImage = (data: any) => {
        const filename = 'event'+this.state.dataRow.id+'_'+new Date().getTime()+'.png';
        const file = new File( [data.uint8], filename, { lastModified: new Date().getTime(), type: data.mime,} )
        data.value && this.setState({dataRowMedia: {...this.state.dataRowMedia, endPoint: MedialibraryModel.endPoint, endPointDelete: MedialibraryModel.endPoint2, dataRow:{id:this.state.dataRow.imageId, files: file, ref: Model.endPointMedia, field:'image'}}, stopCapture: true, saveCapture: true,});
    }

    private getTimeRemaining(timeLimit: number) {
        if (timeLimit>0) {
            var startDate = new Date(this.state.dataRow.startdate);
            this.getNetworkStatus();
            var timeAdj = (timeLimit+this.state.offlineDuration)*1000;
            var timeLeft = Math.ceil(((new Date(startDate).getTime() + timeAdj) - new Date().getTime())/1000);
            if (timeLeft<0) timeLeft = 0;
            this.state.isOnline && timeLeft>=0 && setTimeout(() => this.setState({timeLeft: timeLeft}), 1000);
        }
    }

    private getNetworkStatus() {
        setTimeout(() => {
            const offlineTime = localStorage.getItem('offlineTime');
            this.setState({isOnline: navigator.onLine})
            if (navigator.onLine) {
                if (offlineTime) {
                    this.setState({offlineDuration: this.state.offlineDuration + Math.ceil((new Date().getTime() - +offlineTime)/1000)})
                    localStorage.removeItem('offlineTime')
                }
            }
            else if (!offlineTime) localStorage.setItem('offlineTime',new Date().getTime().toString());
        }, 6000)
    }

    private getPermission = (data: any) => {
        this.setState({allowCamera: data})
    }

    private onSaveQuestion = async(data:any) => {
        if (data && this.state.valueQuestion<=this.state.totalQuestion) {
            const valueQuestion = this.state.valueQuestion + (data.create? 1 : data.delete? -1 : 0);
            await this.setState({valueQuestion: valueQuestion});
            if (this.state.totalQuestion-valueQuestion === 0 && !data.disablescroll)
                document.getElementById('header')?.scrollIntoView(false);
        }
    }

    private onSaveTimeout = (data: any) => {
        localStorage.removeItem('endDate'+data?.id);
    }  

    private onSaveCamera = (data: any) => {
        this.setState({saveCapture: false});
    }

    private onSaveShare = async(data: any) => { 
        this.getDataRowEdit();
        this.setState({ sendReport: true});
    }

    private timeout() {
        if (!this.state.dataRow.enddate && this.state.dataRowEventsection.timelimit>0) {
            this.getTimeRemaining(this.state.dataRowEventsection.timelimit);
            if (this.state.timeLeft===0) localStorage.setItem('eventEnddate'+this.state.dataRow.id, AppService.dateCurrent());
        }
        return <></>
    }    

    private displayMsg(imgUrl: string, title: string, content: string, actionButton?: React.ReactNode) {
        return (
            <Card>
                <Grid container alignItems='center' sx={{padding: {xs:1, md: 5}}} >
                    <Grid item xs={12} md={6}>
                        <img src={imgUrl} width='100%' height='auto' alt='img' /> 
                    </Grid>
                    <Grid item xs={12} md={6}>
                    <Typography component='div' sx={{typography: {xs: 'subtitle1', md: 'h4'},  textAlign: {xs: 'center', md: 'right'}}} >
                        <b>{title}</b><br/><br/>
                        <TextToHtml content={content} sx={{typography: {xs: 'subtitle1', md: 'h4'}, lineHeight: {xs: 1.2, md: 1.5}}}/>                   
                        <br/><br/>
                        <Grid container justifyContent={{xs: 'center', md: 'right'}}>
                            {actionButton}
                        </Grid>
                    </Typography>
                    </Grid>
                </Grid>
            </Card>
        )
    }

    private displayMsgWelcome() {
        const canStart = AppService.dateISO(this.state.dataRow.startdate) < AppService.dateCurrent();
        const participantCaption = this.state.dataRowEvent?.participantCaption || this.state.dataRowUser.personalinfoCaption || UserModel.getUserCaption();
        
        return this.displayMsg(
            //'https://img.freepik.com/free-vector/welcome-word-flat-cartoon-people-characters_81522-4207.jpg?w=900&t=st=1692630043~exp=1692630643~hmac=ad41d758004893abfa6c9b2e3dba033eb50c1fe67f8ddd45194a80e2483fddb2',
            //'https://img.freepik.com/free-vector/market-launch-concept-illustration_114360-1498.jpg?t=st=1726401595~exp=1726405195~hmac=fd7f3af54015986cc7c6df7ba1c2f7ad967f86ec487259599f23ad089aa71e27&w=996',
            process.env.PUBLIC_URL + '/assets/soulutics/eventstart.png',
            'Hai '+participantCaption+',',
            '<b>Selamat Datang di '+this.state.dataRow.name+'</b><br><br>'+ 
                (canStart? 'Selanjutnya '+(this.state.dataRowEventtype?.camera==='yes'? 'mohon untuk mengaktifkan kamera, <br>':'')+'tetap tenang dan semoga berhasil!'
                :'Nantikan, tes akan di mulai pada <br/>' + AppService.dateFormat(this.state.dataRow.startdate ||'', 'd mon y, pukul h:n')),
            canStart && <EventCreateByuser history={this.props.history} parentId={this.state.dataRow.id || 0} participantId={this.state.dataRowUser.personalinfo} participantCaption={participantCaption} getDataRow={this.getDataRowEvent} disableView/>
        )
    }

    private displayMsgTimeout() {
        return this.displayMsg(
            'https://img.freepik.com/free-vector/deadline-concept-illustration_114360-6003.jpg?w=740&t=st=1688314925~exp=1688315525~hmac=6eec512377def350aa53daca201cd61eb2c0317a3895ac2d9e82acf980ada081',
            'Waktu Habis!',
            'Silahkan lanjut ke tahap berikutnya.',
            <BaseEdit history={this.props.history} caption=' ' buttonCaption='Lanjut'
                model={Model} dataRow={this.state.dataRowEdit} getDataRow={this.getDataRowEdit} onSave={this.onSaveTimeout}disableBackButton disableCard/>
        )
    }

    private displayMsgExtraTime(allow?: boolean) {
        return this.displayMsg(
            'https://img.freepik.com/free-vector/flat-design-time-management-concept_23-2148813012.jpg?w=740&t=st=1689087361~exp=1689087961~hmac=d4654cae3c1372bc22e14f3a273fdc0aa4f5f903fa31d58709acc09cdbc17bc6',                    
            'Maaf, Sesi Pengujian Telah Berakhir!',
            allow? 'Jika pengujian belum selesai </br> karena adanya kendala teknis, <br> silahkan hubungi admin untuk <br> melakukan perpanjangan.'
            :'Terima Kasih.'
            )
    }

    private displayMsgOffline() {
        return this.displayMsg(
            'https://img.freepik.com/free-vector/no-connection-concept-illustration_114360-5947.jpg?w=740&t=st=1689572410~exp=1689573010~hmac=4f33bda2337d572799a3c01198e9442cb8ce62bdfea24feee70d2546bade51ba',           
            'Koneksi Tidak Terhubung!',
            'Sepertinya koneksi internetmu tidak tersedia, </br> silahkan periksa kembali.',
            )
    }

    private displayMsgPermission() {
        return this.displayMsg(
            'https://img.freepik.com/free-vector/huge-camera-tiny-people-taking-pictures-photographer-with-camera-photos-landscapes-flat-vector-illustration-photography-occupation-concept-banner-website-design-landing-web-page_74855-23089.jpg?w=996&t=st=1694412220~exp=1694412820~hmac=7199948b54c0be434071ee461c9b569585c6ef3dda8d54235790c95582db7317',
            (this.state.allowCamera===false? 'Kamera Tidak Dizinkan!' : 'Pengecekan Izin Kamera...'),
            'Mohon untuk mengizinkan penggunaan kamera melalui <br> <i>Permission setting</i> di browser',
        )
    }

    private displayMsgNextTest() {
        if ((this.state.totalQuestion>0) && (this.state.valueQuestion>=this.state.totalQuestion-this.state.dataRowEventsection.questiontolerance)) 
            return this.displayMsg(
                'https://img.freepik.com/free-vector/successful-businessman-celebrating-victory_1150-39772.jpg?w=1060&t=st=1689088401~exp=1689089001~hmac=4d601f80e8497c25373b8e955d8201a1448c5334c657050cc8cb4d0d57ace033',
                'Yeay! Kamu berhasil menyelesaikan tahap ini!',
                'Silahkan periksa kembali jawabanmu </br> Jika sudah, lanjutkan ke tahap berikutnya.',
                <BaseEdit history={this.props.history} caption=' ' buttonCaption='Lanjut'
                    model={Model} dataRow={this.state.dataRowEdit} onSaveBefore={this.getDataRowEdit} disableBackButton disableCard/>
            )
    }

    private displayMsgNextTest2() {
        if (this.state.dataRowEventsection.eventsectionLength>0 && this.state.dataRowEventsection.eventsectionLength===this.state.dataRow.eventLengthEnddate)             
            return this.displayMsg(
                'https://img.freepik.com/free-vector/successful-businessman-celebrating-victory_1150-39772.jpg?w=1060&t=st=1689088401~exp=1689089001~hmac=4d601f80e8497c25373b8e955d8201a1448c5334c657050cc8cb4d0d57ace033',
                'Yeay! Kamu berhasil menyelesaikan tahap ini!',
                'Silahkan lanjut ke tahap berikutnya.',
                <BaseEdit history={this.props.history} caption=' ' buttonCaption='Lanjut'
                    model={Model} dataRow={this.state.dataRowEdit} getDataRow={this.getDataRowEdit} disableBackButton disableCard/>
            )
    }

    private displayMsgFinish() {
        if (this.state.finish && this.state.dataRowEventsection.eventsectionLength===0 && this.state.totalQuestion===0 && this.state.dataRowParent.id && !this.state.dataRowParent.parent)
            return this.displayMsg(
                'https://img.freepik.com/free-vector/business-victory-concept_1284-15760.jpg?w=740&t=st=1703007777~exp=1703008377~hmac=75c644063c62089a4c46cc8fe40fd09cc78628c8f082202143e6f7234df2abe1',           
                'Horee! Semua tahap sudah selesai',
                this.state.dataRowEdit.enddate? 'Terima kasih atas partisipasinya.<br/> Sampai jumpa!' : 'Silahkan untuk mengirim hasil tes <br/>dan tunggu hingga proses selesai.',
                <>
                <BaseEdit history={this.props.history} caption=' ' buttonCaption=' '
                    model={Model} dataRow={this.state.dataRowEdit} save={this.state.sendReport} disableBackButton disableAutoBack disableCard/>
                {!this.state.sendReport && !this.state.dataRowEdit.enddate && this.state.dataRowReportgroup.report.length>0 &&
                <EventShare history={this.props.history} id={this.state.dataRowParent.id} buttonCaption='Kirim Hasil' reportgenerateCaption='Mohon menunggu, hasil sedang dibentuk dan akan dikirim.' participantId={this.state.dataRow.participant?.[0]?.id} reportId={this.state.dataRowReportgroup.report?.[0]?.id} to={this.state.dataRowParent.customerEmail} onSave={this.onSaveShare} disableView/>
                }
                </>
            )
        
    }

    private displayCamera() {
        if (this.state.dataRow.imageId) return;
        if (this.state.dataRowEventtype?.camera==='yes' && !this.state.stopCapture) 
            return <Webcam hideCam shotTimeout={5000} stop={this.state.stopCapture} width={200} height={200} getImage={this.getImage}/>  
            
        else if (this.state.saveCapture) 
            return <BaseEdit history={this.props.history} caption=' ' buttonCaption=' ' save={this.state.saveCapture}
                        model={Model} dataRow={this.state.dataRow} dataRowMedia={this.state.dataRowMedia} getDataRow={this.getDataRowCamera} onSave={this.onSaveCamera} disableAutoBack disableBackButton/>
    }

    private displayHeader() {
        return (
            <Grid id='header' container direction='column' p={2}>   
                <Grid container justifyContent='right'>
                    <Grid position='fixed' zIndex={100}>
                        {this.state.totalQuestion>1 &&
                        <ProgressBar value={this.state.valueQuestion} totalValue={this.state.totalQuestion} />
                        }
                        {this.state.dataRowEventsection.timelimit>0 && this.state.timeLeft>0 && this.state.timeLeft<60 &&
                        <ProgressBar caption='detik' value={this.state.timeLeft<0? 0 : this.state.timeLeft} totalValue={60} color='#ff5722' bgColor='#ffd6c9' showValue floating/>
                        }
                    </Grid>
                </Grid>
        
                {this.historyState?.eventInfo?.startDate &&
                <Typography component='div' sx={{typography: {xs: 'body2', md: 'subtitle1'}}} mb={1}>
                    <Icon name='today' size='small'/> <b>{this.historyState?.eventInfo?.startDate} - {this.historyState?.eventInfo?.endDate}</b>
                </Typography>
                }
                <Typography component='div' sx={{typography: {xs: 'body2', md: 'subtitle1'}}} mb={1}>
                    <Icon name='account_circle' size='small'/> <b>{this.state.dataRow.participantCaption}</b>
                </Typography>         
                <Typography component='div' sx={{typography: {xs: 'caption', md: 'body2'}}} mb={1}>
                    {this.historyState?.eventInfo?.status==='extratime' && <Chip label='Perpanjangan Waktu' color='info' sx={{size: {xs: 'small', md:'medium'}, marginRight: 2}}/> }
                </Typography>
                <Typography sx={{typography: {xs: 'subtitle1', md: 'h6'}}} mt={1}>
                    <b>{this.state.isQuiz? this.state.dataRowEventsection.title : this.state.dataRow.name}</b>
                </Typography>     
            </Grid>
        )
    }

    private displayContent() {
        return (
            <Grid container spacing={1}>
            {this.state.isQuiz && this.state.dataRowEventsection.eventsectionLength===0 && this.state.dataRowEventsection.id?
                <>
                {this.displayCamera()}
                <QuestionListByuser history={this.props.history} eventsectionId={this.state.dataRow.eventsectionId} answered={this.state.valueQuestion} viewStyle={this.state.dataRowEventsection.viewstyle} getDataRow={this.getDataRowQuestion} onSave={this.onSaveQuestion} readOnly={this.state.saveCapture}/>
                </>
            :   !this.state.finish?
                    this.state.dataRow.eventsectionId?
                    <EventsectionListByuser history={this.props.history} parentId={this.state.dataRow.eventsectionId} eventParentId={this.state.dataRow.parent} eventId={this.state.dataRow.id} viewStyle={this.state.dataRowEventtype.viewstyle} />
                    : <EventsectionListByuser history={this.props.history} eventParentId={this.state.dataRow.parent || this.state.dataRow.id} eventId={this.state.dataRow.id} viewStyle={this.state.dataRowEventtype?.viewstyle} getDataRow={this.getDataRowEventsectionList} />
                : ''
            }
            </Grid>
        )
    }

    private displayLists() {
        if (this.state.dataRow.id>0)
        return (
            this.state.isQuiz && !this.state.dataRow.parent?
                this.displayMsgWelcome()
            :
                this.state.dataRowEventtype?.id>0 && this.state.dataRowEventtype?.camera==='yes' && this.state.allowCamera===false? 
                    this.displayMsgPermission()
                :        
                    <Card color='secondary.dark' gradientColor='white'>
                        {this.displayHeader()}
                        {this.displayMsgFinish()}
                        {this.displayMsgNextTest()}
                        {this.displayMsgNextTest2()}
                        {this.displayContent()}
                        {this.timeout()}
                    </Card>
        )
    }

    render() {
        var viewStyle = this.state.dataRowEventsection.eventsectionLength===0 && this.state.dataRowEventsection.id? this.state.dataRowEventsection.viewstyle : this.state.dataRowEventtype.viewstyle;

        return (
            //editable..
            <>
            {this.historyState?.id &&
            <ParallaxScroll backgroundElement={
                <BaseView history={this.props.history} caption={this.historyState?.eventInfo?.name || 'Invalid Event!'} captionColor='primary.dark'
                    endPointFilter={Model.endPointFilter4} model={Model} getDataRow={this.getDataRow} disableBackButton={!viewStyle.includes('canback')} disableCard>   
                    {!this.state.dataRow.parent &&
                    <BaseView id={+UserModel.getUser()} caption='' 
                        model={UserModel} getDataRow={this.getDataRowUser}/>
                    }
                    {this.state.dataRow.eventtype &&
                    <BaseView id={this.state.dataRow.eventtype} caption='' 
                        model={EventtypeModel} getDataRow={this.getDataRowEventtype}/>
                    }
                    {this.state.dataRowEventtype.reportgroup &&
                    <BaseView id={this.state.dataRowEventtype.reportgroup} caption='' 
                        model={ReportgroupModel} getDataRow={this.getDataRowReportgroup}/>
                    }
                    {this.state.dataRow.eventsection?.[0] &&
                    <BaseView id={this.state.dataRow.eventsection[0].id} caption=' ' 
                        model={eventsectionModel} getDataRow={this.getDataRowEventsection} disableBackButton/>
                    }
                    {this.state.dataRow.parent &&
                    <BaseView id={this.state.dataRow.parent} caption=' ' 
                        model={Model} getDataRow={this.getDataRowParent} disableBackButton/>
                    }
                </BaseView>
                }
            >
                {this.state.isQuiz && <ScreenGuard disable={!this.state.dataRow.parent} />}
                {this.state.dataRowEventtype.camera==='yes' && 
                <Webcam hideCam stop={true} getPermission={this.getPermission}/>
                }
                {this.historyState?.eventInfo?.name?
                this.state.isOnline?
                    !this.state.dataRowParent.parent && this.state.dataRowParent.enddate && AppService.dateISO(this.state.dataRowParent.enddate) < AppService.dateCurrent()?  
                        this.state.dataRowEventtype.extratime==='yes'?
                            (this.state.dataRow.status!=='extratime'? 
                                this.displayMsgExtraTime(true) : this.displayLists()
                            )
                        : this.displayMsgExtraTime()
                    : this.state.dataRowEventsection.timelimit>0 && (localStorage.getItem('eventEnddate'+this.state.dataRow.id))? 
                        this.displayMsgTimeout() : this.displayLists()
                : this.displayMsgOffline()
                :''}
            </ParallaxScroll>
            }
            </>
        );
    }
}