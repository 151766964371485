import React from 'react';
import {Redirect, Route, Switch } from 'react-router-dom';
import { Box } from '@mui/material';
import { Card, LayoutContainer } from '../../../components';
import { UserRouteAuth } from '../../user/user-route-auth';
import { ProductRoute } from '../../product/product-route';
import { AddressRoute } from '../../address/address-route';
import { CivilnumberRoute } from '../../civilnumber/civilnumber-route';
import { TransactionRouteSales } from '../../transaction/transaction-route-sales';
import { TransactiondetailRoute } from '../../transactiondetail/transactiondetail-route';
import { TransactionamountRoute } from '../../transactionamount/transactionamount-route';
import { TransactionpaymentRoute } from '../../transactionpayment/transactionpayment-route';
import UserModel from '../../user/user-model';
import Home from './home.component';
import ApplayoutThemes from '../applayout-themes.component';
import ApplayoutDashboard from '../applayout-dashboard.component';
import ApplayoutLogo from '../applayout-logo.component';

interface IProps { 
    getThemes: (data: any) => void;
}

interface IState {
}

export default class Index extends React.Component<IProps, IState> {

    constructor(props: IProps) {
      super(props);
      this.state = {
      }

      this.props.getThemes(ApplayoutThemes('brown'))
    }

    public static imageDefault = 'https://img.freepik.com/free-photo/copy-space-natural-treatment_23-2148574960.jpg?t=st=1716351549~exp=1716355149~hmac=5baddf61cbcc5240fd1d929fc590117e9b66b8f1f324aec9620263cc6c525606&w=996';

    render() {
      const root = '/dashboard';
      
      return (
        //editable..
          <Box overflow='hidden'>
          <Switch>
          <Route path='/' component={Home} exact/>  
          <Route path='/user' render={(params)=> <LayoutContainer {...params} header={<ApplayoutLogo/>} children={<UserRouteAuth isSignin={UserModel.isSignin()}/>}/>} />
          <Route path='/product/viewbyuser' render={(params)=> <LayoutContainer {...params} header={<ApplayoutLogo/>} children={<ProductRoute />}/>} />
          {UserModel.isSignin()?
          <>
          <Route path='/dashboard' render={(params)=> <ApplayoutDashboard {...params} root={root} defaultView={<Card media={<img src={Index.imageDefault} width='100%' alt='img' />} />} />} /> 
          <Route path='/address' render={(params)=> <LayoutContainer {...params} header={<ApplayoutLogo/>} children={<AddressRoute/>}/>} />
          <Route path='/civilnumber' render={(params)=> <LayoutContainer {...params} header={<ApplayoutLogo/>} children={<CivilnumberRoute/>}/>} />
          <Route path='/transactionsales' render={(params)=> <LayoutContainer {...params} header={<ApplayoutLogo/>} children={<TransactionRouteSales/>}/>} />
          <Route path='/transactiondetail' render={(params)=> <LayoutContainer {...params} header={<ApplayoutLogo/>} children={<TransactiondetailRoute/>}/>} />
          <Route path='/transactionamount' render={(params)=> <LayoutContainer {...params} header={<ApplayoutLogo/>} children={<TransactionamountRoute/>}/>} />
          <Route path='/transactionpayment' render={(params)=> <LayoutContainer {...params} header={<ApplayoutLogo/>} children={<TransactionpaymentRoute/>}/>} />
          </>
          :
          <Redirect to='/' />
          }
          <Route path='/' component={Home} exact/>  
          </Switch>
        </Box>
      );
    }
}