import React from 'react';
import { History } from 'history';
import { Dashboard } from '../../components';
import UserModel from '../user/user-model';
import MenuPicklistCard from '../menu/menu-picklistcard.component';
import MenugroupPicklistCard from '../menugroup/menugroup-picklistcard.component';
import ApplayoutLogo from './applayout-logo.component';
import ApplayoutRoutes from './applayout-routes.component';

interface IProps { 
  history: History;
  root?: string;
  caption?: string;
  defaultView?: React.ReactNode;
}

interface IState {
  sidebarCollapse: boolean;
  menugroupId: number;
  menugroupCaption: string;
}

export default class ApplayoutDashboard extends React.Component<IProps, IState> {

    constructor(props: IProps) {
      super(props);
      this.state = {
        sidebarCollapse: false,
        menugroupId: 0,
        menugroupCaption: '',
      }
    }

    private getSidebarCollapse = (data: any) => {
      this.setState({ sidebarCollapse: data })
    }
    
    private getDataRowMenugroup = (data: any) => {
      this.setState({ menugroupId: data?.id, menugroupCaption: data?.name })
    }

    public componentDidMount() {
      var historyState: any = this.props.history.location.state;

      //Update menugroupId Base on Current History States
      if (historyState?.menugroupId) this.setState({menugroupId: historyState?.menugroupId})
    }

    public componentDidUpdate() {
      var historyState: any = this.props.history.location.state;
      if (!historyState?.menugroupId && this.state.menugroupId>0) this.props.history.replace(this.props.history.location.pathname, {...historyState, menugroupId: this.state.menugroupId});    
    }  
    
    render() {
      var historyState: any = this.props.history.location.state;
        return (
          //editable..
          <Dashboard history={this.props.history} root={this.props.root} caption={this.props.caption || 'Hai '+UserModel.getUserCaption()} 
            header={<ApplayoutLogo caption={this.state.sidebarCollapse? ' ':''}/>} 
            sidebar={this.state.menugroupId === 0 || (historyState?.historyLengthRoot && historyState?.historyLength === historyState?.historyLengthRoot)?
              <MenugroupPicklistCard history={this.props.history} root={this.props.root} caption=' ' getDataRow={this.getDataRowMenugroup} cardCount={1} cardIconOnly={this.state.sidebarCollapse}/>
              : this.state.menugroupId>0 && <MenuPicklistCard history={this.props.history} root={this.props.root} caption={this.state.sidebarCollapse? ' ' : this.state.menugroupCaption} captionThin menugroupId={this.state.menugroupId} cardCount={this.state.sidebarCollapse? 1 : 2} cardIconOnly={this.state.sidebarCollapse}/>
            }
            defaultView={this.props.defaultView} getSidebarCollapse={this.getSidebarCollapse}> 
              <ApplayoutRoutes history={this.props.history} root={this.props.root}/> 
          </Dashboard> 

        );
    }
}