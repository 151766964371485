import * as React from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';
import { Icon } from './components';
import { CssBaseline, ThemeProvider, Typography } from "@mui/material";

import 'bootstrap/dist/css/bootstrap.min.css'; 
import 'toastr/build/toastr.min.css'; 
import './App.css'

import BaseView from './pages/base/base-view.component'; 
import AppModel from './pages/app/app-model'; 
import OwnerModel from './pages/owner/owner-model';  
import UserModel from './pages/user/user-model';
import Applayout from './pages/applayout/applayout.component';
import ApplayoutThemes from './pages/applayout/applayout-themes.component';
import AppService from './services/app.service';
import ApiService from './services/api.service';

const App = () =>{
  
  const [appData, setAppData] = React.useState(null);
  const [themes, setThemes] = React.useState(ApplayoutThemes('blank'));
  const [error, setError] = React.useState();

  const appInit = () => {
    const getDataRow = (data: any) => {
      setAppData(data);
      OwnerModel.setOwner(data?.owner, data?.ownerCaption);
      document.title = data?.name;  
      const favicon = document.querySelector("link[rel~='icon']") as HTMLAnchorElement; 
      if (favicon) favicon.href = data?.imageUrl;
      if (!UserModel.isSignin()) UserModel.removeUser();
    }

    const getDataError = (data: any) => {
      setError(data);
      if (data && !data?.[0]?.includes('Server')) UserModel.removeUser();
    }

    return (
      <BaseView id={+AppService.appId()} caption=' '
        model={AppModel} getDataRow={getDataRow} getDataError={getDataError}/>
    )
  }

  const getThemes = (data: any) => {
    setThemes(data)
  }

  return (
    <>
    {appInit()} 
    {error?.[0] && 
      <Typography sx={{typography: {xs: 'subtitle1', md: 'h6'}}} color='red' p={5}>
        <Icon name='gpp_bad'/> {error?.[0]}. Please reload in a moment!.
      </Typography>
    }
    {(appData?.['id'] || !ApiService.apiUrl) &&
    
      <ThemeProvider theme={themes}> 
        <CssBaseline />
        <BrowserRouter>
          <Switch>
          <Applayout getThemes={getThemes}/>
          </Switch>
        </BrowserRouter>
      </ThemeProvider>

    }
    </> 
  );
}

export default App;
