import * as React from 'react';
import { History } from 'history';
import { Box, Container, Grid, Typography } from '@mui/material';
import AppModel from '../../app/app-model';
import OwnerModel from '../../owner/owner-model';
import UserModel from '../../user/user-model';
import UserAuthSignin from '../../user/user-auth-signin.component';
import { AppBar, Card, ParallaxScroll, Trapezoid } from '../../../components';
import BaseView from '../../base/base-view.component';
import ApplayoutLogo from '../applayout-logo.component';
import AppService from '../../../services/app.service';

interface IProps {
  history: History;
  getDataRow: (data: any) => void;
}

interface IState {
  triggerTransition: boolean;
  dataRowApp: AppModel;
  dataRowOwner: OwnerModel;
  dataRowUser: UserModel;
}

export default class Home extends React.Component<IProps, IState> {
  
  constructor(props: IProps) {
    super(props);
    this.state = {
      triggerTransition : true,
      dataRowApp: AppModel.defaultValue(),
      dataRowOwner: OwnerModel.defaultValue(),
      dataRowUser: UserModel.defaultValue(),
    }
  }

  private getDataRowApp = (data:any) => {
    this.setState({dataRowApp: data})
  }

  private getDataRowOwner = (data:any) => {
    this.setState({dataRowOwner: data})
  }

  private getDataRowUser = (data:any) => {
      this.setState({dataRowUser: data})
      UserModel.setUserImage(data?.imageUrl || '')
  }

  private onClickAppBar = () =>{
    setTimeout(() => this.setState({ triggerTransition: true}), 100);
  }

  private onScrollEndAppBar = () =>{
    this.setState({ triggerTransition: false })
  }

  private avatarClick = () =>{
    if (UserModel.isSignin()) this.props.history.push('/user/profile')
  }
  
  private isMobile: boolean = AppService.isMobile();

  private header(){
    return (
      <>
      <Trapezoid position='bottomLeft' colorOthers='black'>
        <ParallaxScroll height={this.isMobile? 10 : 50} 
          backgroundElement={
            <Box height={0}>
              <img src="https://webstockreview.net/images/clipart-music-concert-10.png" width='auto' height={700} alt='img'/>
            </Box>
          }
        > 
          <Grid container>
            <Grid item xs={12} md={6}> 
              <Typography variant='h2'>
                <b>Cari tiket?</b> <br/>
                di
                <Box display='inline' color='white'>
                  <b> tiketin </b>
                </Box>
                aja.
              </Typography>  
            </Grid>

            <Grid item xs={12} md={6}>
              <Container>
              {UserModel.isSignin()?
                <></>
                :
                <Card borderColor='white'>
                <Typography sx={{typography: {xs: 'subtitle1', md: 'h6'}}} color='primary.main' mt={3} mb={3}>Login</Typography>
                <UserAuthSignin caption=' ' history={this.props.history} root='/' disableBackButton disableAutoBack></UserAuthSignin>
                </Card>
              }
              </Container>
              
            </Grid>

          </Grid>
              
            <Box height={110}></Box>
        </ParallaxScroll>
      </Trapezoid>
      <Box height={500} bgcolor='black'></Box>
      </>
    )
  }

 

  private footer(){
    return (
      <Trapezoid position='topRight' colorOthers='black'  >
        <Grid container spacing={5} sx={{ padding: {xs: 5, md:0}, paddingBottom: {xs: 5, md:5}}}>
          <Grid item xs={12} md={4}>
            <ApplayoutLogo/>
            <Typography sx={{typography: {xs: 'caption', md: 'body2'}}} mt={2}>
            {this.state.dataRowApp.description}
            </Typography>
            <Typography sx={{typography: {xs: 'caption', md: 'body2'}}} mt={2}>
            {this.state.dataRowOwner.name}
            </Typography>
          </Grid>
          
          <Grid item xs={12} md={2}>
            <Typography sx={{typography: {xs: 'subtitle1', md: 'h6'}}}>
              <b>Hubungi Kami</b>
            </Typography>
            <Typography sx={{typography: {xs: 'caption', md: 'body2'}}} mt={2}>
              Call Center <br/>
              {this.state.dataRowOwner.phone}
            </Typography>
            <Typography sx={{typography: {xs: 'caption', md: 'body2'}}} mt={2}>
              Email <br/>
              {this.state.dataRowOwner.email}
            </Typography>
            <Typography sx={{typography: {xs: 'caption', md: 'body2'}}} mt={2}>
              Instagram
            </Typography>
            <Typography sx={{typography: {xs: 'caption', md: 'body2'}}} mt={2}>
              Facebook
            </Typography>
          </Grid>

          <Grid item xs={12} md={2}>
            <Typography sx={{typography: {xs: 'subtitle1', md: 'h6'}}}>
              <b>Kebijakan</b>
            </Typography>
            <Typography sx={{typography: {xs: 'caption', md: 'body2'}}} mt={2}>
              Kebijakan Pribadi
            </Typography>
            <Typography sx={{typography: {xs: 'caption', md: 'body2'}}} mt={2}>
              Syarat & Ketentuan
            </Typography>
            <Typography sx={{typography: {xs: 'caption', md: 'body2'}}} mt={2}>
              Sering ditanyakan
            </Typography>
          </Grid>
        </Grid>
      </Trapezoid>
    )
  }
  private home(){
    return (
      <>      
      <BaseView id={+AppService.appId()} caption='' 
        model={AppModel} getDataRow={this.getDataRowApp}/>
      
      <BaseView id={+OwnerModel.getOwner()} caption='' 
        model={OwnerModel} getDataRow={this.getDataRowOwner}/>

      {UserModel.isSignin() &&
      <BaseView id={+UserModel.getUser()} caption='' 
        model={UserModel} getDataRow={this.getDataRowUser}/>
      }
      {this.header()}
      {this.footer()}
      </>
    )
  }
  
  public render(): React.ReactNode {  
    return (
      <AppBar 
        logo = {<ApplayoutLogo/>}
        menu = {[{caption:UserModel.isSignin()?'Dashboard':'', url:'/dashboard'}]}
        avatar = {{name:this.state.dataRowUser.name, imageUrl:this.state.dataRowUser.imageUrl, onClick: this.avatarClick}}
        onClick={this.onClickAppBar}
        onScrollEnd={this.onScrollEndAppBar}
      >
        {this.home()}
      </AppBar>
    )
  }
}