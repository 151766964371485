import React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import { Box } from '@mui/material';
import { Card, LayoutContainer } from '../../../components';
import { UserRouteAuth } from '../../user/user-route-auth';
import { ProductRoute } from '../../product/product-route';
import { EventRouteEventtype } from '../../event/event-route-eventtype';
import UserModel from '../../user/user-model';
import Home from './home.component';
import ApplayoutThemes from '../applayout-themes.component';
import ApplayoutDashboard from '../applayout-dashboard.component';
import ApplayoutLogo from '../applayout-logo.component';

interface IProps { 
    getThemes: (data: any) => void;
}

interface IState {
}

export default class Index extends React.Component<IProps, IState> {

    constructor(props: IProps) {
      super(props);
      this.state = {
      }
      this.props.getThemes(ApplayoutThemes('purple'))
    }

    //public static imageDefault = 'https://img.freepik.com/free-vector/natural-background-with-mountains-landscape-gradient_23-2148274814.jpg?w=996&t=st=1700759498~exp=1700760098~hmac=c764924b4acb21cca8c64aadcbf5e8caaa8e5cf836ee8d90f4d617eb6f69e7b4';
    //public static imageDefault = 'https://img.freepik.com/free-vector/mountains-landscape-with-high-peaks_52683-24163.jpg?t=st=1726319426~exp=1726323026~hmac=b4bb42a8ff0646a287e8f53bf5ee0b99bc852aa1968ddf1dd89c44e9c5b909b1&w=996';
    public static imageDefault = process.env.PUBLIC_URL + '/assets/soulutics/appbg.jpg';

    render() {
      const root = '/dashboard';
      return (
        //editable..
        <Box overflow='hidden'>
          <Switch>
          <Route path='/' component={Home} exact/>  
          <Route path='/user' render={(params)=> <LayoutContainer {...params} header={<ApplayoutLogo/>} children={<UserRouteAuth isSignin={UserModel.isSignin()}/>}/>} />
          <Route path='/product/viewbyuser' render={(params)=> <LayoutContainer {...params} header={<ApplayoutLogo/>} children={<ProductRoute />}/>} />
          {UserModel.isSignin()?
          <>
          <Route path='/dashboard' render={(params)=> <ApplayoutDashboard {...params} root={root} defaultView={<Card media={<img src={Index.imageDefault} width='100%' alt='img' />} />} />} /> 
          <Route path='/quiz' render={(params)=> <LayoutContainer {...params} header={<ApplayoutLogo/>} children={<EventRouteEventtype/>}/>} />
          </>
          :
          <></>
          }
          </Switch>
        </Box>
      );
    }
}