import React from 'react';
import { History } from 'history';
import { Box, Container, Grid, Typography } from '@mui/material';
import { AppBar, Button, Card, Dialog, DragDrop, ParallaxScroll, Table, Transition, Trapezoid } from '../../../components';
import AppModel from '../../app/app-model';
import OwnerModel from '../../owner/owner-model';
import UserModel from '../../user/user-model';
import BaseView from '../../base/base-view.component';
import UserAuthSignin from '../../user/user-auth-signin.component';
import EventPicklistCard from '../../event/event-picklistcard.component';
import ProductPicklistCard from '../../product/product-picklistcard.component';
import ApplayoutLogo from '../applayout-logo.component';
import AppService from '../../../services/app.service';

interface IProps {
  history: History;
}

interface IState {
  triggerTransition: boolean;
  dataRowApp: AppModel;
  dataRowOwner: OwnerModel;
  dataRowUser: UserModel;
  eventLength: number;
  openDialog: boolean;
}

export default class Home extends React.Component<IProps, IState> {
  
  constructor(props: IProps) {
    super(props);
    this.state = {
      triggerTransition: true,
      dataRowApp: AppModel.defaultValue(),
      dataRowOwner: OwnerModel.defaultValue(),
      dataRowUser: UserModel.defaultValue(),
      eventLength: 0,
      openDialog: false,
    }
  }

  private isMobile: boolean = AppService.isMobile();

  private mentorHeader = [
    {name: 'imageUrl', type: 'media'}
  ];

  private mentorDataSet = [
    {id: 1, imageUrl: 'https://img.freepik.com/free-photo/modern-muslim-woman-wearing-headscarf-walking-street_1303-27637.jpg?w=360&t=st=1687459247~exp=1687459847~hmac=b4eeb1f8c95efdb1979599a803584b26d8a6d03c91983e1c22c13d7e61f1899d'},
    {id: 2, imageUrl: 'https://img.freepik.com/free-photo/asian-woman-glasses-smart-blouse-skirt-posing-studio-with-tablet_1098-17489.jpg?w=360&t=st=1687459012~exp=1687459612~hmac=41035a5f21d96fcffbe0db09ab2f625ae49fc3a467654db5079300549f302384'},
    {id: 3, imageUrl: 'https://img.freepik.com/free-photo/confident-businessman_1098-16852.jpg?w=360&t=st=1687459085~exp=1687459685~hmac=0625f84521fff0f6e49c725f46b16e70301eecea5ad7ea3b68e5d819a8b20131'},
    {id: 4, imageUrl: 'https://img.freepik.com/free-photo/modern-muslim-woman-wearing-headscarf-walking-street_1303-27637.jpg?w=360&t=st=1687459247~exp=1687459847~hmac=b4eeb1f8c95efdb1979599a803584b26d8a6d03c91983e1c22c13d7e61f1899d'},
    {id: 5, imageUrl: 'https://img.freepik.com/free-photo/asian-woman-glasses-smart-blouse-skirt-posing-studio-with-tablet_1098-17489.jpg?w=360&t=st=1687459012~exp=1687459612~hmac=41035a5f21d96fcffbe0db09ab2f625ae49fc3a467654db5079300549f302384'},
    {id: 6, imageUrl: 'https://img.freepik.com/free-photo/confident-businessman_1098-16852.jpg?w=360&t=st=1687459085~exp=1687459685~hmac=0625f84521fff0f6e49c725f46b16e70301eecea5ad7ea3b68e5d819a8b20131'},
  ];

  private getDataRowApp = (data:any) => {
    this.setState({dataRowApp: data})
  }

  private getDataRowOwner = (data:any) => {
    this.setState({dataRowOwner: data})
  }

  private getDataRowUser = (data:any) => {
      this.setState({dataRowUser: data})
      UserModel.setUserImage(data?.imageUrl || '')
  }

  private getDataRowEvent = (data: any) => {
    if (data.eventtypeType) setTimeout(() => this.props.history.push('/'+data.eventtypeType, {id: data.id}), 1);
  };

  private getDataSetEvent = (data: any, pageInfo: any) => {
    this.setState({ eventLength: pageInfo.total})
  };

  private getDataRowProduct = (data: any) => {
    setTimeout(() => this.props.history.push('/product/viewbyuser', {id: data.id}), 1);
  };

  private onClickAppBar = () =>{
    setTimeout(() => this.setState({ triggerTransition: true}), 100);
  }

  private onScrollEndAppBar = () =>{
    this.setState({ triggerTransition: false })
  }

  private avatarClick = () =>{
    if (UserModel.isSignin()) this.props.history.push('/user/profile')
    else this.setState({openDialog: true});
  }

  private UserSignin(){
    return (
      <Dialog open={this.state.openDialog} onClose={()=>this.setState({openDialog: false})}>
        <Card borderColor='white'>
          <Typography sx={{typography: {xs: 'subtitle1', md: 'h6'}}} color='primary.main' mt={3} mb={3}>Login</Typography>
          <UserAuthSignin caption=' ' history={this.props.history} root='/' onSave={()=>this.setState({openDialog: false})} disableBackButton disableAutoBack/>
        </Card>
      </Dialog>
    )
  }

  private header(color: any, colorOthers: any){
    return ( 
      <Trapezoid position='bottomLeft' colorOthers={colorOthers} fullWidth disableMargin> 
        <ParallaxScroll height={this.isMobile? 10 : 50} 
          backgroundImageUrl='https://img.freepik.com/free-vector/natural-background-with-mountains-landscape-gradient_23-2148274814.jpg?w=996&t=st=1700759498~exp=1700760098~hmac=c764924b4acb21cca8c64aadcbf5e8caaa8e5cf836ee8d90f4d617eb6f69e7b4'
        >    
          <Container>
            <Grid container pb={this.isMobile? 20 : 50}>
              <Grid item xs={12} md={6} pb={3}>
                <Transition variant='growCenter' direction='down' trigger>
                  <Typography component='div' color={this.isMobile? 'whitesmoke' : ''} sx={{typography: {xs: 'h3', md: 'h2'}, lineHeight: {md: 1}}} pt={10} >
                    <b>Kenalan yuk,</b> <br/>
                    dengan bakatmu <br/>
                    di  <Grid display='inline' color={this.isMobile? 'primary.main' : 'white'}>Kotak Bicara</Grid>
                  </Typography>
                </Transition>
                
                <Transition variant='slide' direction='right' trigger>
                  <Box>
                    <Typography sx={{typography: {xs: 'subtitle1', md: 'h6'}, lineHeight: {xs:1.2, md: 1.2}}} pt={2} mb={3}>
                      Dengan cara berbeda, keahlian tersembunyimu bisa digali sekarang.
                      Kamu bisa melakukannya dengan tenang dimana saja, 
                      sehingga kamu bisa dengan mudah menentukan arah selanjutnya.
                    </Typography>
                    <Button variant='contained' color='secondary' label='Mulai Dimana Saja' onClick={()=>document.getElementById('products')?.scrollIntoView(false)}/>
                  </Box>
                </Transition>
              </Grid>
              
              <Card borderColor={this.isMobile? 'primary.main':''} borderRadius={this.isMobile? 15 : 30} padding={this.isMobile? 3 : 5}>
                <Grid container spacing={this.isMobile? 1 : 15} textAlign='center'> 
                  <Transition variant='zoom' trigger>        
                    <Grid item xs={4} md={4}>
                      <Typography sx={{typography: {xs: 'h4', md: 'h3'}}} color='primary.main'>
                        <b>10k</b>+
                      </Typography> 
                      <Typography sx={{typography: {xs: 'caption', md: 'body2'}}} color='primary.light' mt={this.isMobile? 0 : 2}>
                        <b>Peserta Tercerahkan</b>
                      </Typography>
                    </Grid>
                  </Transition>

                  <Transition variant='zoom' addTime={500} trigger>
                    <Grid item xs={4} md={4}>
                      <Typography sx={{typography: {xs: 'h4', md: 'h3'}}} color='primary.main'>
                        <b>99</b>+
                      </Typography>    
                      <Typography sx={{typography: {xs: 'caption', md: 'body2'}}} color='primary.light' mt={this.isMobile? 0 : 2}>
                        <b>Test Berkualitas</b>
                      </Typography>
                    </Grid>
                  </Transition>

                  <Transition variant='zoom' addTime={1000} trigger>
                    <Grid item xs={4} md={4}>
                      <Typography sx={{typography: {xs: 'h4', md: 'h3'}}} color='primary.main'>
                        <b>20</b>+
                      </Typography>           
                      <Typography sx={{typography: {xs: 'caption', md: 'body2'}}} color='primary.light' mt={this.isMobile? 0 : 2}>
                        <b>Pembimbing Berpengalaman</b>
                      </Typography>
                    </Grid>
                  </Transition>
                </Grid>
              </Card>

              <DragDrop dropper>
                <DragDrop><Card>card 1</Card></DragDrop>
                <DragDrop><Card>card 2</Card></DragDrop>
              </DragDrop>
              <DragDrop dropper>
              </DragDrop>
              <DragDrop dropper>
              </DragDrop>
            </Grid>

          </Container>
        </ParallaxScroll>
      </Trapezoid>
    )
  }
  
  private events(color: any, colorOthers: any){
    return (
      <Box id='events' bgcolor={color} p={3}>
        <Transition parentId='events' trigger={this.state.triggerTransition}>
          <Container >
          {UserModel.isSignin() &&
            <Card color='secondary.light'>
              <EventPicklistCard caption='Mulai Pengujianmu Sekarang!' getDataRow={this.getDataRowEvent} getDataSet={this.getDataSetEvent}/>
            </Card>
          }
          </Container>
        </Transition>
      </Box>
    )
  }

  private products(color: any, colorOthers: any){
    return (
      <Box id='products'bgcolor={color} p={3}>
        <Transition parentId='products' variant='fade' trigger={this.state.triggerTransition}>
          <Container>
            <Grid container spacing={2}>
              <Transition parentId='products' addTime={1000}>
                <Typography sx={{typography: {xs: 'h5', md: 'h4'}}} textAlign='center'>
                  <b>Test Berkualitas</b>
                </Typography>
              </Transition>
              <Grid container item mt={2}>
                <ProductPicklistCard caption='Paling banyak dicari' pricetype='pricesale' cardCount={4} swipe='horizontal' getDataRow={this.getDataRowProduct}/>
              </Grid>
              <Grid container item mt={2}>
                <ProductPicklistCard caption='Tes Tingkat Manajemen' pricetype='pricesale' cardCount={4} swipe='vertical' getDataRow={this.getDataRowProduct}/>
              </Grid>
              <Grid container item mt={2}>
                <ProductPicklistCard caption='Semua Tes' pricetype='pricesale' cardCount={3} getDataRow={this.getDataRowProduct}/>
              </Grid>
            </Grid>
          </Container>
        </Transition>
      </Box>
    )
  }

  private mentors(color: any, colorOthers: any){
    return (
      <Box id='mentors' bgcolor={color} p={3}>
        <Transition parentId='mentors' variant='fade' trigger={this.state.triggerTransition}>
          <Container>
            <Grid container direction='row' spacing={1} alignItems='center' justifyContent='center'>            
              <Grid item xs={12} md={3} textAlign='right' mb={3}>
                <Transition parentId='mentors' addTime={2000}>
                  <Typography sx={{typography: {xs: 'h4', md: 'h3'}}} color='primary.light'>
                    <b>Pembimbing</b>
                  </Typography>
                </Transition>
                <Transition parentId='mentors' addTime={3000}>
                  <Typography sx={{typography: {xs: 'h5', md: 'h4'}, lineHeight: {xs: 1, md: 1}}} >
                    <b>yang telah Berpengalaman</b>
                  </Typography>
                </Transition>
                <Transition parentId='mentors' addTime={4000}>
                  <Typography sx={{typography: {xs: 'h6', md: 'h5'}, lineHeight: {xs: 1, md: 1}}} color='primary.light'>
                    50 tahun <br/>di bidangnya
                  </Typography>
                </Transition>
              </Grid>
              <Grid item xs={12} md={9}>
                <Table header={this.mentorHeader} dataSet={this.mentorDataSet} isReady cardCount={3} readOnly cardView swipe={'horizontal'}/> 
              </Grid>
              <Grid item xs={12} md={12}>
                <Table header={this.mentorHeader} dataSet={this.mentorDataSet} isReady cardCount={5} cardSpacing={1} readOnly cardView swipe={'vertical'}/> 
              </Grid>

            </Grid>
          </Container>
        </Transition>
      </Box>
    )
  }

  private footer(color: any, colorOthers: any){
    return (
      <Trapezoid id='footer' position='topLeft' color={color} colorOthers={colorOthers} fullWidth>
        <Transition parentId='footer' trigger={this.state.triggerTransition}>
          <Grid container spacing={5} sx={{ padding: {xs: 5, md:0}, paddingBottom: {xs: 5, md:5}}}>
            <Transition parentId='footer' addTime={1000}>
              <Grid item xs={12} md={4}>
                <ApplayoutLogo/>
                <Typography sx={{typography: {xs: 'caption', md: 'body2'}}} mt={2}>
                {this.state.dataRowApp.description}
                </Typography>
                <Typography sx={{typography: {xs: 'caption', md: 'body2'}}} mt={2}>
                {this.state.dataRowOwner.name}
                </Typography>
              </Grid>
            </Transition>
            
            <Transition parentId='footer' addTime={2000}>
              <Grid item xs={12} md={2}>
                <Typography sx={{typography: {xs: 'subtitle1', md: 'h6'}}}>
                  <b>Hubungi Kami</b>
                </Typography>
                <Typography sx={{typography: {xs: 'caption', md: 'body2'}}} mt={2}>
                  Call Center <br/>
                  {this.state.dataRowOwner.phone}
                </Typography>
                <Typography sx={{typography: {xs: 'caption', md: 'body2'}}} mt={2}>
                  Email <br/>
                  {this.state.dataRowOwner.email}
                </Typography>
                <Typography sx={{typography: {xs: 'caption', md: 'body2'}}} mt={2}>
                  Instagram
                </Typography>
                <Typography sx={{typography: {xs: 'caption', md: 'body2'}}} mt={2}>
                  Facebook
                </Typography>
              </Grid>
            </Transition>

            <Transition parentId='footer' addTime={3000}>
              <Grid item xs={12} md={2}>
                <Typography sx={{typography: {xs: 'subtitle1', md: 'h6'}}}>
                  <b>Kebijakan</b>
                </Typography>
                <Typography sx={{typography: {xs: 'caption', md: 'body2'}}} mt={2}>
                  Kebijakan Pribadi
                </Typography>
                <Typography sx={{typography: {xs: 'caption', md: 'body2'}}} mt={2}>
                  Syarat & Ketentuan
                </Typography>
                <Typography sx={{typography: {xs: 'caption', md: 'body2'}}} mt={2}>
                  Sering ditanyakan
                </Typography>
              </Grid>
            </Transition>
          </Grid>
        </Transition>
      </Trapezoid>
    )
  }
  
  private home(){
    return (
      <>      
      <BaseView id={+AppService.appId()} caption='' 
        model={AppModel} getDataRow={this.getDataRowApp}/>
      
      <BaseView id={+OwnerModel.getOwner()} caption='' 
        model={OwnerModel} getDataRow={this.getDataRowOwner}/>

      {UserModel.isSignin() &&
        <BaseView id={+UserModel.getUser()} caption='' 
          model={UserModel} getDataRow={this.getDataRowUser}/>
      }
      
      {this.UserSignin()}
      {this.header('', 'secondary.dark')}
      {this.events('secondary.dark', '')}
      {/*this.header('', 'white')*/}
      {/*this.events('white', '')*/}
      {this.products('white', '')}
      {this.mentors('secondary.dark', '')}
      {this.footer('secondary.light', 'secondary.dark')}
      </>
    )
  }

  private menus() {
    return [{caption:UserModel.isSignin()?'Event':'', section:'events', icon: 'event', badge: this.state.eventLength}, {caption:'Produk', section:'products', icon: 'inventory'}, {caption:'Mentor', section:'mentors', icon: 'face_4'}, {caption:UserModel.isSignin()?'Dashboard':'', url:'/dashboard', icon: 'widgets'}];
    //return [{caption:UserModel.isSignin()?'Event':'', section:'events', icon: 'event', badge: this.state.eventLength}, {caption:UserModel.isSignin()?'Dashboard':'', url:'/dashboard', icon: 'widgets'}];
  }
  
  public componentDidMount() {
    
    //Clear The History First, Before Back To Home
    if (this.props.history.length>3){
      this.props.history.go(-this.props.history.length+2);
      this.props.history.replace('/');
    }
  }

  public render(): React.ReactNode {  
    return (
      <AppBar
        color= 'secondary.main'
        logo= {<ApplayoutLogo/>}
        menu= {this.menus()}
        avatar= {{name:this.state.dataRowUser.name, imageUrl:this.state.dataRowUser.imageUrl, onClick: this.avatarClick}}
        onClick= {this.onClickAppBar}
        onScrollEnd= {this.onScrollEndAppBar}
      >
        {this.home()}
      </AppBar>
    )
  }
}